
import { Component, Vue } from 'vue-property-decorator'
import { SoilHumidityDetail, TypeList } from '../../types/ecologyMonitor.d'
import * as echarts from 'echarts'

@Component({
  name: 'History'
})
export default class InsectHistory extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private loading = false
  private dataList: Array<SoilHumidityDetail> = []
  page = 1
  size = 10
  total = 0

  get deviceNumber () {
    return this.$route.params.deviceNumber as string
  }

  private echart: any
  private echartSelect = '0'
  private echartData: {ah: Array<string>; at: Array<string>; collectTime: Array<string>} = {
    ah: [],
    at: [],
    collectTime: []
  }

  private typeList: Array<TypeList> = []

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const val = this.$dayjs().format('YYYY-MM-DD')
    const vals = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
    this.searchInfo.dateRange = [vals, val]
  }

  getData () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.loading = true
      this.$axios.get(this.$apis.ecologyMonitor.selectInsectDataByPage, {
        deviceNumber: this.deviceNumber,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1],
        page: this.page,
        size: this.size
      }).then((res) => {
        this.total = res.total || 0
        this.dataList = res.list || []
        this.getEchartsData()
      }).finally(() => {
        this.loading = false
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 图表数据
  getEchartsData () {
    this.echartData = {
      at: [],
      ah: [],
      collectTime: []
    }
    if (this.dataList.length > 0) {
      this.dataList.forEach(item => {
        this.echartData.at.push(item.at)
        this.echartData.ah.push(item.ah)
        this.echartData.collectTime.push(item.collectTime)
      })
    }
    this.drawEchart(this.echartData.at, this.echartData.ah, this.echartData.collectTime)
  }

  drawEchart (at: string[], ah: string[], date: string[]) {
    this.echart = echarts.init(this.$refs.echart)
    const option = {
      title: {
        text: '温度湿度统计'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { // 坐标轴指示器，坐标轴触发有效
          type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      legend: {
        type: 'plain',
        left: 25,
        top: 40,
        icon: 'rect',
        itemHeight: 2,
        itemWidth: 12,
        itemGap: 16,
        textStyle: {
          color: 'rgba(140, 140, 140, 1)'
        }
      },
      grid: {
        bottom: 30,
        top: 80,
        left: 55,
        right: 30
      },
      color: ['#2C8EFFFF', '#FFC117FF'],
      xAxis: [
        {
          type: 'category',
          data: date,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#00000073'
            }
          },
          axisLabel: {
            margin: 15
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#00000026'
            }
          },
          axisLabel: {
            color: '#00000073'
          }
        }
      ],
      series: [
        {
          name: '温度',
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: '#2C8EFF33',
            shadowOffsetY: 20
          },
          data: at
        },
        {
          name: '湿度',
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: '#FFC11733',
            shadowOffsetY: 20
          },
          data: ah
        }
      ]
    }
    this.echart.setOption(option)
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }
}
